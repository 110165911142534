// import required partials
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/spinners";

// Row
.row {
    @include make-row();
}
// Columns
@include make-grid-columns();
